
import { defineComponent, Ref, ref, watch, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";
import CryptoJS from 'crypto-js';

interface Question {
  input_type: string; // Assuming input_type is a string
  id: string; // Assuming id is a string
  // Add other properties as needed
}
export default defineComponent({
  mixins: [apiEndpoint],
  name: "PublicSurvey",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const route = useRoute();
    const { bus } = useBus();
    const formData: Ref<{ [key: string]: any }> = ref({});
    const surveyTitle = ref(null);
    const submitButton = ref<HTMLElement | null>(null);
    const questionList = ref<Question[]>([]);
    const errors = ref({});
    const secretKey = 'blims@997#123';

    const decryptId = (encryptedId) => {
      const bytes = CryptoJS.AES.decrypt(encryptedId, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    };

    const loadQuestionList = async () => {
      try {
        const encryptedId = decodeURIComponent(route.params.id as string);

        const id = decryptId(encryptedId);


        const response = await ApiService.get(
            `${apiEndpoint.data().VUE_APP_PUBLIC_SURVEY_WISE_QUESTIONS}?survey_id=${id}`
        );
        surveyTitle.value = response.data.data.title;
        questionList.value = response.data.data.questions;
        questionList.value.forEach((item) => {
          if (item.input_type === "checkbox") {
            formData.value[item.id] = [];
          }
        });
      } catch (error) {
        console.error("Error loading data for update:", error);
      }
    };

    const save = async () => {
      try {
        submitButton.value?.setAttribute("data-kt-indicator", "on");

        // await schema.validate(formData.value, { abortEarly: false });

        formData.value.survey_id = decryptId(route.params.id);

        ApiService.post(
          apiEndpoint.data().VUE_APP_PUBLIC_SURVEY_STORE,
          formData.value
        )
          .then((response) => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            console.log(response.data);
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        // formData.value = {
        //   component_id: "",
        //   parent_output_id: "",
        //   output_id: "",
        //   title: "",
        // };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    const getDateValueFormat = (inputType: string) => {

      if (inputType === 'date') {
        return 'YYYY-MM-DD';
      } else if (inputType === 'year') {
        return 'YYYY';
      } else if (inputType === 'daterange') {
        return 'YYYY-MM-DD';
      }

      return 'YYYY-MM-DD';
    };

    const getDateFormat = (inputType: string) => {
      if (inputType === 'date') {
        return 'DD/MM/YYYY';
      } else if (inputType === 'year') {
        return 'YYYY';
      } else if (inputType === 'daterange') {
        return 'DD/MM/YYYY';
      }
      return 'DD/MM/YYYY';
    };

    watch(
      () => route.params.id,
      (newData) => {
        if (newData) {
          loadQuestionList();
        }
      }
    );

    onMounted(() => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      if (route.params.id) {
        loadQuestionList();
      }

      bus.on("loadQuestionList", async () => {
        loadQuestionList();
      });
    });

    return {
      surveyTitle,
      questionList,
      formData,
      save,
      submitButton,
      getDateValueFormat,
      getDateFormat,
    };
  },
});
